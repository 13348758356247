<!--
 * @Descripttion:
 * @version:
 * @Author: guomengxia
 * @Date: 2024-02-01 10:05:02
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-03-15 10:05:58
-->
<template>
  <div>
    <div class="set-box">
      <div class="set-box-title">
        <com-title style="margin:24px 0px;">操作记录</com-title>
      </div>
      <div class="content">
        <el-steps direction="vertical">
          <el-step
            v-for="(item,index) in operationRecord"
            :key="index"
            :title="item.content"
            :description="item.createTime">
          </el-step>
        </el-steps>
      </div>
    </div>
  </div>
</template>

<script>
// getAddOperateRecordApi
import { getOperationRecordApi } from '@/api/api'
import comTitle from '@/components/comTitle/index.vue'
export default {
  components: {
    comTitle
  },
  data () {
    return {
      operationRecord: []
    }
  },
  created () {
    this._getOperationRecord()
  },
  methods: {
    // 获取操作记录
    _getOperationRecord () {
      const params = {
        businessId: this.$route.query.paperId, // 试卷ID
        businessType: 2// 业务类型;1:学习计划;2:试卷;3:课程
      }
      getOperationRecordApi(params).then((res) => {
        if (res.code === 200) {
          // console.log(res.data, 'ghfvb')
          this.operationRecord = res.data
          // el-step 图标里的数字反向排序
          this.$nextTick(() => {
            var x = document.querySelectorAll('.el-step__icon-inner')
            x.forEach((item, index) => {
              item.innerHTML = x.length - index
            })
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  padding: 0;
}
/deep/ .el-step__title{
    color: #48494D !important;
    font-weight: 500;
}
/deep/.el-step__description{
    margin-bottom: 36px;
    color: #A8ABB2;
}
// /deep/.el-step__main{
//   margin-top: 0px;
// }
/deep/.el-step.is-vertical .el-step__title{
  color: rgba(0,0,0,0.85);
  font-size: 14px;
  font-weight: 500;

}
/deep/.el-step__description.is-wait{
 color: #B2B2B2!important;
  font-size:12px;
  font-weight: 400;
}
/deep/.el-step__description{
  margin-bottom: 16px;
}
/deep/.el-step__head.is-wait{
  color: #909399;
}
</style>
